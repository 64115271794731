export const homePageRoute = "/";

export const notfoundPageRoute = "/*";
export const authPageRoute = "/auth";
export const eventPageRoute = "/events";
export const gallery = "/events/:id/gallery";
export const galleryImages = "/events/:id/gallery/images";
export const galleryVideos = "/events/:id/gallery/videos";
export const projectRoute = "/projects";
export const singleProjectRoute = "/projects/:pid";
export const profileRoute = "/profile";
export const profileEditRoute = "/profile/edit";
export const myEventPageRoute = "/my-events";
export const recommendedEventPageRoute = "/recommended-events";
export const loginPageRoute = "/auth/login";
export const forgetPasswordFormRoute = "/auth/forgetPasswordForm";
export const registerPageRoute = "/auth/register";
