export const genderOptions = (t) => {
  return [
    {
      name: t("male"),
      id: "1",
    },
    {
      name: t("female"),
      id: "2",
    },
  ];
};
